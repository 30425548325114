import { Environment } from './environment-type';

export const environment: Environment = {
  production: false,
  translationBaseUrl: '',
  stage: 'dev',
  auth: {
    clientID: '',
    domain: '',
    audience: '',
  },
  backendUrl: '',
  connectToDevTools: true,
  serviceChargeReconciliationLinksEnabled: true,
  intranet: '',
  caaBaseUrl: '',
  features: {
    defaultYear: 2019,
    yearsAvailableInFilter: [],
    operationalCostsSettlement: {
      downloadSummary: { years: [] },
      confirmSettlementEnabled: {},
      masterInvoicePoolStartYear: 2022,
    },
    invoiceLinesShowOriginalValues: {
      enabledUsers: [],
    },
  },
  adminUsers:[]
};
