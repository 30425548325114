import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ApolloError, isApolloError } from '@apollo/client/core';
import { BehaviorSubject } from 'rxjs';

import { MessageListDialogComponent } from '../components/message-list-dialog/message-list-dialog.component';

export interface ErrorMessage {
  id: number;
  message: string;
  requestId?: string | unknown;
}

type Errors = UncaughtPromiseError | HttpErrorResponse | Error | string;

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  get errors(): BehaviorSubject<ErrorMessage[]> {
    return this.messages;
  }

  private messages = new BehaviorSubject<ErrorMessage[]>([]);
  private messageId = 1;
  private dialogRef: MatDialogRef<MessageListDialogComponent> | undefined;

  constructor(private dialog: MatDialog) {}

  sendError(error: Errors): number {
    const ignoredErrorStatuses = [401, 403];

    const status = this.getStatus(error);

    if (!status || !ignoredErrorStatuses.includes(status)) {
      let message: ErrorMessage;

      message = this.constructErrorMessage(error);

      return this.send(message);
    } else {
      return -1;
    }
  }

  clearMessages(): void {
    this.messages.next([]);

    this.closeDialog();
  }

  private constructErrorMessage(error: Errors): ErrorMessage {
    const text = this.getMessageString(error) || '';
    let requestId: string | unknown;

    if (MessageService.isApolloError(error)) {
      requestId = MessageService.getRequestId(error);
    }

    return {
      id: this.messageId++,
      message: text,
      requestId,
    };
  }

  private send(message: ErrorMessage): number {
    const list = this.messages.value;
    list.push(message);
    this.messages.next(list);

    this.openDialog();

    return message.id;
  }

  private openDialog() {
    const dialogOptions: MatDialogConfig = {
      disableClose: true,
      autoFocus: false,
      panelClass: 'message-list-dialog',
      width: '100%',
      position: {
        top: '0',
      },
    };

    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(MessageListDialogComponent, dialogOptions);
    }
  }

  private closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = undefined;
    }
  }

  private getMessageString(error: Errors): string {
    if (typeof error === 'string') {
      return error;
    }

    if (MessageService.isUncaughtPromiseError(error)) {
      return error.rejection.networkError?.message;
    }

    if (MessageService.isError(error)) {
      return error.message;
    }

    return 'Unknown Error';
  }

  private getStatus(error: Errors): number | null {
    if (typeof error === 'string') {
      return null;
    }

    if (MessageService.isUncaughtPromiseError(error)) {
      return error.rejection.networkError?.status;
    }

    if (MessageService.isNetworkError(error)) {
      return error.status;
    }

    return null;
  }

  private static isUncaughtPromiseError(error: Errors): error is UncaughtPromiseError {
    if (typeof error === 'string') {
      return false;
    }

    if (error.hasOwnProperty('rejection')) {
      const promiseError: UncaughtPromiseError = error as UncaughtPromiseError;
      return promiseError.rejection?.networkError;
    }
    return false;
  }

  private static isNetworkError(error: Errors): error is HttpErrorResponse {
    if (typeof error === 'string') {
      return false;
    }

    return error.hasOwnProperty('status');
  }

  private static isApolloError(error: Errors): error is ApolloError {
    if (typeof error === 'string') {
      return false;
    }

    return isApolloError(error);
  }

  private static isError(error: Errors): error is Error {
    if (typeof error === 'string') {
      return false;
    }

    return error.hasOwnProperty('message');
  }

  private static getRequestId(error: ApolloError): string | unknown {
    const gqlError = error.graphQLErrors[0];

    if (gqlError && gqlError.extensions && gqlError.extensions.requestId) {
      return gqlError.extensions.requestId;
    }
  }
}
