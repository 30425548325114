import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { environment } from '../../../environments/environment';
import { version } from '../../../version/version';
import { MessageService } from '../../shared/services/message.service';

const sentryHandler = Sentry.createErrorHandler();

@Injectable()
class CustomErrorHandler extends ErrorHandler {
  constructor(private messageService: MessageService) {
    super();
  }

  handleError(error: any) {
    const messageId = this.messageService.sendError(error);
    if (messageId > 0 && !location.href.includes('localhost')) {
      sentryHandler.handleError(error);
    } else {
      super.handleError(error);
    }
  }
}

export function initSentry() {
  Sentry.init({
    dsn: 'https://422e70ff66c3446986fef5e935136d1e@o143872.ingest.us.sentry.io/4504932867375104',
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [
      // Instrumental reporting for 401
      /401/,
      // version.json polling outside the VPN
      /403/,
      /exception captured with keys: error, headers, message, name, ok/,
      /Unknown Error/,
    ],
    release: 'RAT-' + version.git.commitHash,
    environment: environment.stage,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class SentryModule {}
