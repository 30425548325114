import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { initSentry } from './app/core/modules/sentry.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

fetch('/config/config.json')
  .then((res) => res.json())
  .then((config) => Object.assign(environment, config))
  .then(() => import('./app/app.module').then((a) => a.AppModule))
  .then((a) => {
    if (environment.production) {
      enableProdMode();
    }
    initSentry();
    platformBrowserDynamic()
      .bootstrapModule(a)
      .catch((err) => console.error(err));
  })
  .catch((err) => console.error(err));
