import { Version } from './version.model';

export const version: Version = {
  build: {
    time: '2024-09-10T08:18:58+0000',
  },
  git: {
    branchName: 'master',
    commitHash: '0edbdd00',
  },
};
